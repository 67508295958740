// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA7Mzh7jDRFSgNwDyAQPVW36GVEjPLadVY",
  authDomain: "hddfcc-2c7dc.firebaseapp.com",
  databaseURL: "https://hddfcc-2c7dc-default-rtdb.firebaseio.com",
  projectId: "hddfcc-2c7dc",
  storageBucket: "hddfcc-2c7dc.appspot.com",
  messagingSenderId: "270410634588",
  appId: "1:270410634588:web:8eb15136bc62f93516dd99",
  measurementId: "G-8T2DD6ZT64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };